import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["icon"];

  copy(event) {
    event.preventDefault();
    const input = document.querySelector("#token-display");
    if (input) {
      input.select();
      document.execCommand("copy");
      this.showIcon();
    }
  }

  showIcon() {
    this.iconTarget.classList.remove("hidden");
    setTimeout(() => {
      this.iconTarget.classList.add("hidden");
    }, 2000); // Hide icon after 2 seconds
  }

}
